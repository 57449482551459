@import './assets/styles/mixins';

.search_result_game_card {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-start;
  width: calc(20% - 36px);

  @include maxWidth($desktop-m) {
    width: calc(25% - 33.75px);
  }

  @include maxWidth($desktop-s) {
    width: calc(25% - 26.25px);
    margin-bottom: 40px;
  }

  @include maxWidth($tablet-l) {
    margin-bottom: 25px;
    width: calc(33% - 20px);
  }

  @include maxWidth($tablet-s) {
    max-width: unset;
    width: calc(50% - 15px);
  }

  @include maxWidth($mobile-xl) {
    width: calc(50% - 18px);
    margin-bottom: 17px;
  }

  @include maxWidth($mobile-l) {
    width: calc(50% - 10px);
  }

  &__descr {
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: $placeholder-color;
    opacity: 0.7;
    text-overflow: ellipsis;

    @include maxWidth($tablet-l) {
      font-size: 16px;
    }
  }

  &__image {
    border-radius: 30px;
    flex-shrink: 0;
    overflow: hidden;
    aspect-ratio: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;

    @include maxWidth($mobile-xl) {
      border-radius: 17.2401px;
    }
  }
}
