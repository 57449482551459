@import 'assets/styles/_mixins.scss';

.loader {
  display: inline-block;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 1.5px solid;
  border-color: $white transparent;
  border-radius: 50%;
  animation: rotation 1s linear infinite;

  &--absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translateY(-50%);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
